import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

@Component({
  name: 'GtrRegistrationModuleEditOptionGroupView',
  computed: {
    ...mapState('option', ['option_group']),
    ...mapState('event', ['eventAllContent']),
    ...mapState('pricing', ['pricing_tiers'])
  }
})
export default class GtrRegistrationModuleEditOptionGroupView extends GtrSuper {
  @Prop()
  readonly observer!: InstanceType<typeof ValidationObserver>

  private readonly systemGroups = [
    '804b2cd2-01fb-4795-8ae8-7140d821c353',
    '4f34ec41-e49e-4683-846f-0c9316135e57',
    'd2069958-b488-45ba-9819-8373337a39fb',
    '709ca31d-5131-4fff-9aeb-fa786c5a5825',
    '8061a3de-3131-451b-ad9f-4d390f3f7fea',
    '848b3948-f577-461f-8a19-ec1d189f30d6'
  ]

  option_groups!: Array<Record<string, any>>;
  option_group: any;

  data () {
    return {
      loading: false,
      submitting: false,
      submittingOptionForm: false,
      optionsYesNo: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      optionGroup: {},
      option: {
        languageToUse: 'en',
        name: null,
        details: {},
        cap: null,
        taxed: 0,
        display: false,
        display_order: false,
        visible: true,
        regtype_specific: false,
        reg_types: []
      },
      options: [],
      pricing_for_option: [],
      display: 1,
      display_order: 0,
      group_display: true,
      default_option_groups: ['Additional User Line Items'],
      registration_types: [],
      pricingTiersForSelect: [],
      pricingTiers: [],
      popupDetails: {
        title: '',
        body: ''
      },
      headers: [
        { text: 'Name', width: '15%', align: 'start', sortable: false, value: 'name' },
        { text: 'Cap', width: '15%', align: 'center', sortable: false, value: 'cap' },
        { text: 'Signed Up', width: '15%', align: 'center', sortable: false, value: 'count' },
        { text: 'Complete', width: '15%', align: 'center', sortable: false, value: 'count_complete' },
        { text: 'Display Order', width: '15%', align: 'center', sortable: false, value: 'display_order' },
        { text: 'Visible', width: '15%', align: 'center', sortable: false, value: 'visible' },
        { text: 'Edit', width: '15%', align: 'center', sortable: false, value: 'edit' },
        { text: 'Delete', width: '15%', align: 'center', sortable: false, value: 'delete' },
        { text: '', width: '15%', align: 'center', sortable: false, value: 'export' }
      ],
      allContent: null
    }
  }

  @Watch('eventAllContent')
  onEventAllContentChange (payload: any) {
    this.$data.allContent = payload
    this.$data.registration_types = payload.option_groups.filter(group => group.name === 'Registration Types')
    this.$data.registration_types = this.$data.registration_types.shift().options
    const registration_types_names = [
      {
        text: 'Default',
        value: '_default'
      }
    ]
    for (let i = 0; i < this.$data.registration_types.length; i++) {
      registration_types_names.push({
        text: this.$data.registration_types[i].name,
        value: this.$data.registration_types[i].uuid
      })
    }
    this.$data.registration_types = registration_types_names
  }

  @Watch('option_group')
  onOptionGroupChange (payload: any) {
    this.$data.optionGroup = payload
    const isSystemGenerated = payload.options?.[0]?.option_group_id < 6
    if (isSystemGenerated) {
      this.$data.headers = this.$data.headers.filter(header => header.value !== 'delete')
    }
  }

  @Watch('languageItems')
  onLanguageItemsChange (payload: any) {
    let counter = 0
    payload.forEach(() => {
      /**
       * Make the first language in the object the default
       */
      if (counter === 0) {
        // this.$data.option.languageToUse = language.value
        this.$data.option.languageToUse = 'en'
      }
      counter++
    })
  }

  @Watch('pricing_tiers')
  onPricingTiersChange (payload: any) {
    if (Array.isArray(payload)) {
      this.$data.pricingTiers = payload
      payload.forEach(tier => {
        this.$data.pricingTiersForSelect.push({
          text: tier.name,
          value: tier.uuid
        })
      })
    }
  }
  // #endregion

  // #region Computed Props
  get isThisADefaultOptionGroup () {
    return this.$data.default_option_groups.includes(this.$data.optionGroup.name)
  }

  get languageItems () {
    const arr: any [] = []
    if (this.$data.allContent) {
      for (const language_key in this.$data.allContent.languages) {
        arr.push({
          text: this.$data.allContent.languages[language_key],
          value: language_key
        })
      }
    }
    return arr
  }
  // #endregion

  // #region Methods
  async mounted () {
    try {
      const groupUuid = this.$route.params.option_uuid
      await this.$store.dispatch('pricing/getPricingTiers', { event_uuid: this.$route.params.event_uuid })
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
      await this.fetchOptions()
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
      Container.get(Notification).error(err.message)
    }
  }
  // #endregion

  async exportOptionGroup () {
    this.$data.loading = true
    const payload = {
      option_group_uuid: this.$route.params.option_uuid,
      event_uuid: this.$route.params.event_uuid
    }
    const response = await this.$store.dispatch('option/exportOptionGroup', payload)
    this.$data.loading = false
    this.checkURLAndGo(response.data)
  }

  async exportOption (option_uuid: string) {
    this.$data.loading = true
    const payload = {
      option_uuid,
      option_group_uuid: this.$route.params.option_uuid,
      event_uuid: this.$route.params.event_uuid
    }
    const response = await this.$store.dispatch('option/exportOption', payload)
    this.$data.loading = false
    this.checkURLAndGo(response.data)
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.option.reg_types = []
    }
  }

  addPricingForOption () {
    const pricing_item = {
      uuid: Vue.prototype.$uuid.v4(),
      reg_type: '',
      tiered_pricing: {}
    }
    /**
     * Add each pricing tier's uuid to the pricing item so that it exists when we try to access it with v-model
     */
    this.$data.pricingTiers.forEach((tier: any) => {
      pricing_item.tiered_pricing[tier.uuid] = {
        price: '0',
        cancel_price: '0'
      }
    })
    this.$data.pricing_for_option.push(pricing_item)
  }

  deletePricingItem (pricing_item_uuid: string) {
    this.$data.pricing_for_option = this.$data.pricing_for_option.filter(
      (pricing_item: any) => pricing_item.uuid !== pricing_item_uuid
    )
  }

  editOption (option_group: any, option: any) {
    this.$router.push({
      name: 'level-two.modules.registration.option.edit',
      params: {
        option_group_uuid: option_group.uuid,
        option_uuid: option.uuid
      }
    })
  }

  async deleteOption (option: any) {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        option_group_uuid: this.$route.params.option_uuid,
        option_uuid: option.uuid
      }
      await this.$store.dispatch('option/deleteOption', payload)
      Container.get(Notification).success('Option successfully deleted.')
      await this.fetchOptions()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async submit () {
    const form = (this.$refs.editOptionGroupForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          option_group_uuid: this.$data.optionGroup.uuid,
          data: {
            name: this.$data.optionGroup.name,
            blank_first: this.$data.optionGroup.blank_first,
            has_cap: this.$data.optionGroup.has_cap,
            display_order: this.$data.display_order,
            group_display: this.$data.optionGroup.group_display
          }
        }
        await this.$store.dispatch('option/saveOptionGroupSettings', payload)
        Container.get(Notification).success('Option group successfully edited.')
        this.$router.push({ name: 'level-two.modules.registration.options', params: this.$route.params })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  async submitOptionForm () {
    const form = (this.$refs.createOptionForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submittingOptionForm = true
        const data = {
          data: {
            name: this.$data.option.name,
            details: this.$data.option.details,
            description: { en: '' }, // this is in case the option is a OptionGroupType::SESSIONGRP
            pricing: this.$data.pricing_for_option,
            taxed: this.$data.option.taxed,
            cap: this.$data.option.cap,
            display: this.$data.option.display,
            display_order: this.$data.option.display_order,
            visible: this.$data.option.visible,
            regtype_specific: this.$data.option.regtype_specific,
            reg_types: this.$data.option.reg_types,
            popup_details: {
              title: this.$data.popupDetails.title,
              body: this.$data.popupDetails.body
            }
          },
          option_group_uuid: this.$route.params.option_uuid,
          event_uuid: this.$route.params.event_uuid
        }
        await this.$store.dispatch('option/createOption', data)
        await this.fetchOptions()
        Container.get(Notification).success('Option successfully created.')
        const observer = (this.$refs.observer as any)
        const form = (this.$refs.createOptionForm as HTMLFormElement)
        // const optionNameProvider = (this.$refs.optionNameProvider as any)
        this.$data.popupDetails.body = ''
        form.reset()
        observer.reset()
        // optionNameProvider.reset()
        this.$data.option = {
          languageToUse: 'en',
          name: null,
          details: {},
          cap: null,
          taxed: 0,
          display: false,
          display_order: false,
          visible: true,
          regtype_specific: this.$data.option.regtype_specific,
          reg_types: this.$data.option.reg_types,
          popupDetails: {
            title: '',
            body: ''
          }
        }
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submittingOptionForm = false
      }
    }
  }

  async fetchOptions () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('option/getOptionGroupSingle', { event_uuid: this.$route.params.event_uuid, option_group_uuid: this.$route.params.option_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
  // #endregion
}
